
import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules";

@Component({
  name: "Login",
  metaInfo: { 
    title: 'Sign Up | SkillToon - Master New Skills with Live Interactive Workshops',
    link: [
      { rel: 'canonical', href: 'https://skilltoon.com/register' }
    ],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/register' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Sign Up | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'og:description', content: 'Sign Up at SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { property: 'og:image', content: 'https://skilltoon.com/logo-color.svg' },
      { name: 'description', content: 'Sign Up at SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/register' },
      { property: 'twitter:title', content: 'Sign Up | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'twitter:description', content: 'Sign Up at SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { property: 'twitter:image', content: 'https://skilltoon.com/logo-color.svg' },
    ]
  }
})
export default class extends Vue {

  async googleSignUp () {
    await Auth.googleSignUp();
  }

  async facebookSignUp () {
    await Auth.facebookSignUp();
  }
}
